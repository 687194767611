import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICustomer, IRole } from 'src/modules/customer/model/customer.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class CustomerService {
  private readonly customerAPI: string = environment.apiUrl + 'v2/customers/';

  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getCustomerRoles(): Observable<IRole[]> {
    return this.http.get<IRole[]>(this.apiUrl + 'Authenticate/Roles');
  }

  public getCustomerSelf(): Observable<ICustomer> {
    return this.http.get<ICustomer>(this.customerAPI + "Customers/Self" );
  }

  
}
