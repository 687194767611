import { ICustomer } from "../model/customer.model";

export class GetCustomer {
  static readonly type = '[Customer] Get Customer';
}

export class GetAuthCustomer {
  static readonly type = '[Customer] Get Auth Customer';
}


export class GetCustomerRoles {
  static readonly type = '[Customer] Get Customer Roles';
}
